@page {
    size: A4;
    margin: 0;
}

.table {
    font-size: 14px;
    line-height: 18px;
    font-family: '宋体';
    padding: 0.5cm 1cm;
    table {
        margin: 0;
    }

    table.bordered {
        border-right: 1px solid #000;
        border-bottom: 1px solid #000;
        margin-bottom:  6px;
        tr > td {
            border: 1px solid #000;
            border-right: none;
            border-bottom: none;
            padding: 2px;
            vertical-align: top;

            & > table {
                border: none;
                tr> td {
                    border: none;
                }
            }
        }
    }


}

@media print {
    .printView {
      
    }
  }