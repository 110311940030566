.cmt-table {
  li[class*='ant-pagination-total-text'] {
    margin-right: auto;
    flex-grow: 1;
  }

  .paginaztion-total {
    text-align: right;
  }
}

.cmt-table-header {
  & :global {
    .ant-form-item,
    .ant-btn {
      margin-bottom: 3px;
    }
  }
}
