@page {
  size: A4;
}
.printView {
  max-width: 754px;
  font-size: 12px;
  margin: 0.5cm 1cm;
  background-image: url('/public/cas/printbg.png');
  & :global {
    h1 {
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      margin: 0;
      padding: 0;
      line-height: 30px;
      height: 30px;
      overflow: hidden;
    }

    h2 {
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      margin: 0;
      padding: 0;
      line-height: 36px;
    }

    h2 span {
      display: block;
      float: right;
      position: absolute;
      margin: -36px 0px 0px 560px;
      width: 200px;
      text-align: right;
      font-size: 12px;
    }

    .usertable {
      margin: 0;
      padding: 0;
      border-collapse: collapse;
    }

    .usertable td {
      margin: 0;
      padding: 0;
      text-align: left;
    }

    .tablelist {
      margin: 0;
      padding: 0;
      border-collapse: collapse;
      word-break: break-all;
    }

    .tablelist th {
      margin: 0;
      padding: 5px;
      border: solid 1px #ccc;
      font-size: 12px;
    }

    .tablelist td {
      margin: 0;
      padding: 5px;
      border: solid 1px #ccc;
      text-align: left;
    }

    .font14 {
      font-size: 14px;
      font-weight: bold;
    }

    table {
      font-size: 12px;
      width: 100%;
    }
  }
}
