.wrapper {
  height: 100%;
  width: 100%;

  & :global {
    .ant-card-body {
      height: 99%;
    }
    .ant-tabs-nav {
      margin-bottom: 0;
    }
    .ant-tabs,
    .ant-tabs-content,
    .ant-tabs-tabpane {
      height: 100%;
    }
  }
}

.edit {
  width: 100%;
  cursor: pointer;
  .action {
    display: none;
    transition: all 0.4 linear;
  }
  &:hover {
    .action {
      display: inline-block;
    }
  }
}
