@page {
  size: A4;
}
.printView {
  font-size: 12px;
  margin: 0.5cm;
  background-image: url('/public/cas/new-printbg.png');
  background-size: 100%;
  & :global {
    h1 {
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      margin: 0;
      padding: 0;
      line-height: 30px;
      height: 40px;
      overflow: hidden;
    }

    h2 {
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      margin: 0;
      padding: 0;
      line-height: 36px;
    }

    h2 span {
      display: block;
      float: right;
      position: absolute;
      margin: -36px 0px 0px 560px;
      width: 200px;
      text-align: right;
      font-size: 12px;
    }

    .usertable {
      margin: 0;
      padding: 0;
      border-collapse: collapse;
      width: 100%;
    }

    .usertable1 {
      margin: 0;
      padding: 0;
      border-collapse: collapse;
      width: 100%;
    }

    .usertable td {
      vertical-align: top;
      margin: 0;
      padding: 0;
      text-align: left;
    }
    .usertable1 td {
      margin: 0;
      padding: 0;
      text-align: left;
    }

    .tablelist {
      margin: 0;
      padding: 0;
      border-collapse: collapse;
      width: 100%;
    }

    .tablelist th {
      margin: 0;
      padding: 5px;
      border: solid 1px #ccc;
      font-size: 12px;
    }

    .tablelist td {
      margin: 0;
      padding: 5px;
      border: solid 1px #ccc;
      text-align: center;
      width: 100px;
      word-break: break-all;
    }

    .font14 {
      font-size: 14px;
      font-weight: bold;
    }

    table {
      font-size: 12px;
    }
    .toptable tr td {
      width: 33%;
      height: 25px;
    }
    .toptable tr td:nth-child(2) {
      padding-left: 30px;
    }
    .toptable tr td:nth-child(3) {
      padding-left: 15px;
    }
    .moneyTr td:nth-child(1) {
      border-width: 0 0 1px 1px;
    }
    .moneyTr td:nth-child(2) {
      border-width: 0 0 1px 0;
    }
    .moneyTr td:nth-child(3) {
      border-width: 0 0 1px 0;
    }
    .moneyTr td:nth-child(4) {
      border-width: 0 1px 1px 0;
      text-align: right;
    }
    .tablelist td p {
      text-align: left;
      margin: 0 5px;
      line-height: 20px;
    }
    .ulList {
      display: flex;
      flex-wrap: wrap;
      margin-top: 5px;
    }
    .ulList li {
      line-height: 25px;
      font-size: 12px;
      list-style: none;
      display: inline-flex;
      width: 25%;
      word-break: break-all;
      padding-right: 10px;
      box-sizing: border-box;
    }
    .ulList li span {
      flex-shrink: 0;
    }
  }
}
